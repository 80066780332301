const whitelistAddresses = [
"0x385061C3FBEe86BD8E9e65e9c6A1B6b8F9C25C3F",
"0x113E2E94C9116442Ade330A7C5c2A9751E6DaaF0",
"0x06658050530D5E06EE8a3513D7697CdeEE28b004",
"0x632668e883aca908133B8fB03c7bC3601DfB5B17",
"0xd7d167d918A3337464953C1C84dBE6f37190c02D",
"0x6EF2A678dC041BF5CC5206f59050FcFD7c28625a",
"0xaf5370fb6BA70D7a9ac557A98958c4e52cfbcE08",
"0x9560bd6313b8074291b2763F6F199934B804c878",
"0x9CC49bdA86ccbbcf6c1Fe010934F353911e40b3c",
"0x85b8A4909BDe9a605e2AE558cbaE25036DcCA032",
"0x5515F6e1d5853FA5C72b9CC69bc7b0a863A46926",
"0x4f761B8A7bEf76405078140c14B0B4e981883861",
"0x12aEFD163FB4Fe15211e74C6Ec1AA7d6533b951c",
"0x9bEEb0C873b3096d1A689fD9aF3B2762Dd5CDcE8",
"0x74c7164411ad3d53be3408B1e978dAefF35AC366",
"0x454DF81f7C75DF5431341D0550961d03C30b2Ef7",
"0xcBC378f2923A8D5a47eaEf88A177Eb273Fa52049",
"0xd45f2708c7585023f213369EbEb9B55A37C88854",
"0x089da352d9b9C929F2e41bd85769faFc31B67376",
"0xdB52bB096e58EC1c6EAE8a2b42DCB54BDA1e20f0",
"0xB424f29C203142b6f0DEb790c298dfAF5aF80C83",
"0x1AA6351138cFd9466E03B03Fe32bc2075fDA954E",
"0x6eBA8cF0B61265996a8a32A4E9cF458eaD2e1768",
"0x90704c032D588b2b3DCC3FBB862B1f854c2d3281",
"0xEC5913e7a1121DB1562f30b46AD43e5E0dC61a59",
"0x4c90e8efEf279a59C380b811003Bb6CcE96453e7",
"0xF2337Df84F13bc4236fc021AfE64F536D7DA4786",
"0xCECC7Ba0bdEd2FaC872484EE8A4FE5F8AEadF0B1",
"0x667E332D2aC68a9F6067425Df181Da988C012413",
"0x52Be806f8eA7D115C3D09748bD8dB91FC8b73F4A",
"0x4947d4Dec9ac03eFa0e3E72423f0d4d5573C6363",
"0x69C9c3297DbCf2424BF37353a531D75Aaeb910d5",
"0x4743409d5427F85793B6697014465BC311062c2C",
"0xAdf92Fcd0D9E527DD5E0061573B606396ca27A9b",
"0x19c7fdF10EFE142CbF0EC340f66dbcaE27cbd063",
"0x78944b5DF9ee0E3F11B7A58C9970F2532d40a611",
"0x7e36d061dcD8f01Fbc51AfE721a0596cC220cA40",
"0x0707F89469509b6E762d4202Af71Fb9D1725592e",
"0xF14197dc4934B4D050c8cF64c24a469CB6e64BdA",
"0xA0ef7A4aFF204afd7E3c60B38a8106ee4C21E7e0",
"0xde4CdAc9eed4461ac103ae63F7ba6DF385a3eC9F",
"0x60655c10aED4157F959a541440F12B833fAb7485",
"0xc729D91DFd7Cb52c905DD28dC1e50DA90CEb7F8C",
"0x6BA023cf806862780C3f6A4285DE325e1644aB0d",
"0x3569f2AAfe9157dB175CeFC7800c615Ea406de56",
"0x72c376a44903c664b690D1ecf786c6a8497B03D5",
"0xb368197788A821d1c757C9aC954DC65A693C823C",
"0x700500acCca5B65e672479E8c1CEBf022aF6EaE4",
"0xEf39aF2671F462E94ebCf319E615fbEd7c9847Ed",
"0xBCCf45dE0226D4f7f102aF139E392392785a2243",
"0xA49f4b060d7386fd8AfD115F0Df52Fb3441AF7f5",
"0x44f78C254706B004Fa1f99a016b94f1faA2ACc69",
"0xe9619C04ea2e62c49d5bC94ECB4bD4F054d256D5",
"0x28Be8727Ae9033bb8A9C6A9c7d254C71CB4C5b8B",
"0xa0b5186D8a3bEBEf1620c4512138AAf42CAD6e18",
"0xDcd3Cc82EE7b8ca7ED55E807F26C694598304523",
"0xc0d0f0ad803c091F95cd33E2aceFbB0a0e001Aaf",
"0x473390b502f643450074560a620496376b3083A9",
"0x877E7fdaA8D76d20c41762D063C3B7947dAc76Bd",
"0x7357f081E79760e157E6C4215a35ad0233260f66",
"0x760b12d09bE022a8baF92190AF9E20F15242e8e4",
"0x77Ea3eE5cd2788a7AC33AC516bF2F22429f705dd",
"0x8cb2ca469526868803Dec461bCE7909766e63873",
"0xD63Abd0c130a9482d76d404416Fd78777be24166",
"0x36b21D1aACa7C078A29a2ACc1f692763341470e9",
"0xC43E80e22764F8795d838C65DF3B95AF4c6797f6",
"0x07CE5667f7559a7bEc0fA87c6731C719afB64dD9",
"0xa2D04359661Dd4AF51763e7E6A7fff9125cCA826",
"0x0a961F0d3E695D242AEd6Cb3090B9b356Dc126b4",
"0xc67505621eae3aA54A12DB52a6D101a3AbF2365c",
"0x02E65F52f46373a60e8f87c16e496111f4F47EF6",
"0x2BA4408279CE96237Ae65F0C23E8667cDb5B7E83",
"0x8B778e3aAf4FC4b355c8613C9DA8c7b75D35310d",
"0x3e395A11a27A6e35798D019Ccd33A3035819098e",
"0x684959fE3ea333499a58A7CA2F412379999d1eb5",
"0x3e675970c5db1fe34fd9466811f322996D4E6d8d",
"0x0A862Bba163cc7a01d1D5A8710203AC827C6b0fD",
"0x5328560b54827BB282b9266E9de1f09727D2430B",
"0x90091297bbCB35C8c76A7aae7784D0B038b54c46",
"0x5684C995788aBAc13f88704ad51076858a634958",
"0xa31F55636cD421a80BAa5748270145445957616E",
"0xFC110131F24c3cD7094FAf9E51635a22Be30D8A0",
"0x4F084f8A42D78A81Ab2EcAF225763d6500C10c6e",
"0x8Fc0654212EBAc05Db04C6F024590D8b97aB6D90",
"0x377AC2A3adA0952e54F538e51865DfC5FABcAEd7",
"0xDA8D38d78589EDcf3F306ca122e1646aF913660D",
"0x88Be8ed5D9d48C6A8024bC4eeB08258edFE42cd9",
"0x76eBE9C899A788338aE31eA10Ba767468d24dA19",
"0x1fd99404FF02C6F5E4974f899365987EFF59474c",
"0xB5d4C304B8887B07E28950e0D95c8B42DFd5502E",
"0x20be67A1F3c49D646640736ec67f6ac3A4E622d4",
"0x9528bc3CA27A5Da1896C65a559f190aaeA00f68D",
"0x62Fe86D6a8E18B2C3CDf4B855620122D7631Ba23",
"0x6174eC790E1EDBb32AF215eD5E2ef021807eFcaF",
"0x088BFA6aED025E945D9D72949f8D56E4f583d0F8",
"0xB1B5D983d118f5ee6597c8F16C790e68193e1607",
"0x1710bcf82fE1EeE9D31c81Bbb571eCBEe2BcdF07",
"0x3c8837de7e561EEcD9aC19B0ACdb62c6c6f43cc5",
"0xcf990161909af6BF4e5c3bcfFF255142Ca86a15f",
"0xE11D08e4EA85dc79d63020d99f02f659B17F36DB"
  
];

export default whitelistAddresses;
